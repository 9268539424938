import React from 'react';

import PropTypes from 'prop-types';

import ExportAsImage from 'components/ui/button/export-as/ExportAsImage';
import ResponsiveTreemap, {
  treemapItemPropTypes,
} from 'components/ui/visualization/Treemap';

import EmptyDataVisualization from './EmptyDataVisualization';

const getTreemapData = (nodeId, data) => {
  if (!data.length) return [];
  const children = data.reduce(
    (accumulator, element) =>
      element.parent_id === nodeId ? [...accumulator, element] : accumulator,
    []
  );
  return children.length
    ? children
    : getTreemapData(data.find((item) => item.id === nodeId).parent_id, data);
};

const getHierarchichalPath = (nodeId, data) => {
  if (!nodeId) return [{ conceptId: null, parent_id: null, label: 'Global' }];
  const idToInfos = data.find((x) => x.id === nodeId);
  return [idToInfos, ...getHierarchichalPath(idToInfos.parent_id, data)];
};

const CHART_ID = 'treemap-chart-explore';
function HierarchicalTreemap({
  parentNodeId,
  data,
  onClick,
  height,
  width,
  ...otherProps
}) {
  const nodeData = getTreemapData(parentNodeId, data);
  const sentiments = data.map(({ average_sentiment }) => average_sentiment);
  const maxSentiment = Math.max(...sentiments);
  const minSentiment = Math.min(...sentiments);
  // Legacy code
  // TODO
  // - add props for chartTitle
  // - remove `getHierarchichalPath` call
  // - remove `getHierarchichalPath` function from file
  const hierarchicalPath = getHierarchichalPath(parentNodeId, data);
  const chartTitle = `Treemap - ${hierarchicalPath[0].label}`;
  if (!data.length) return <EmptyDataVisualization height={height} />;
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
      id={CHART_ID}
    >
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ExportAsImage
          style={{ marginLeft: 'auto' }}
          exportId={CHART_ID}
          exportName={chartTitle}
        />
      </span>
      <ResponsiveTreemap
        {...otherProps}
        minSentiment={minSentiment}
        maxSentiment={maxSentiment}
        key={`treemap-${parentNodeId}`}
        nodeId={parentNodeId}
        data={nodeData}
        onClick={onClick}
        height={height}
        width={width}
      />
    </div>
  );
}

HierarchicalTreemap.propTypes = {
  data: PropTypes.arrayOf(treemapItemPropTypes).isRequired,
  parentNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

HierarchicalTreemap.defaultProps = {
  onClick: () => {},
  parentNodeId: null,
  height: undefined,
  width: '100%',
};

HierarchicalTreemap.getHierarchichalPath = getHierarchichalPath;

export default HierarchicalTreemap;
