import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const StyledMenu = styled(Menu)`
  &&&& {
    margin: 0 auto;
    flex-grow: 1;
    border-bottom: none;
  }
`;
export const SecondaryNavbarContainer = styled.div`
  width: 100%;
  padding-top: ${svars.spaceSmall};
  background: ${svars.colorWhite};
  box-shadow: ${svars.selectedLightBoxShadow};
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${svars.colorLightGrey};
  &&& div > a {
    color: ${svars.colorPrimary};
    font-weight: ${svars.fontWeightMedium};
  }
`;

const ActionsContainer = styled.span`
  display: inline-flex;
  height: 100%;
  margin: 0 ${svars.spaceNormalLarge};
  align-items: center;
`;

function NavigationTabs({ panes, children }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isCurrent = (path) =>
    path ? pathname.endsWith(path.replace('.', '')) : false;
  return (
    <SecondaryNavbarContainer>
      <StyledMenu
        pointing
        secondary
        color="blue"
        data-testid="analysis-menu-tab"
      >
        {panes.map(({ to, content, disabled }, key) => {
          const isCurrentTab = isCurrent(to);
          return (
            <Menu.Item
              disabled={disabled}
              key={`ntmi-${to}`}
              active={isCurrentTab}
              onClick={() => !disabled && navigate(to)}
              style={{
                padding: `${svars.spaceNormalLarge} ${svars.spaceLarge}`,
              }}
              data-testid={`analysis-menu-tab-${key}`}
            >
              <Trans render={capitalizedTranslation} id={content} />
            </Menu.Item>
          );
        })}
      </StyledMenu>
      {children ? <ActionsContainer>{children}</ActionsContainer> : null}
    </SecondaryNavbarContainer>
  );
}

NavigationTabs.propTypes = {
  panes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  children: PropTypes.node,
};
NavigationTabs.defaultProps = {
  children: null,
};
export default NavigationTabs;
