import React from 'react';

import * as svars from 'assets/style/variables';

function MenuBarsIcon() {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.568182 3.4545H15.3409C15.6548 3.4545 15.9091 3.20024 15.9091 2.88632V0.613592C15.9091 0.299672 15.6548 0.0454102 15.3409 0.0454102H0.568182C0.254261 0.0454102 0 0.299672 0 0.613592V2.88632C0 3.20024 0.254261 3.4545 0.568182 3.4545ZM17.6136 6.29541H2.84091C2.52699 6.29541 2.27273 6.54967 2.27273 6.86359V9.13632C2.27273 9.45024 2.52699 9.7045 2.84091 9.7045H17.6136C17.9276 9.7045 18.1818 9.45024 18.1818 9.13632V6.86359C18.1818 6.54967 17.9276 6.29541 17.6136 6.29541ZM15.3409 12.5454H0.568182C0.254261 12.5454 0 12.7997 0 13.1136V15.3863C0 15.7002 0.254261 15.9545 0.568182 15.9545H15.3409C15.6548 15.9545 15.9091 15.7002 15.9091 15.3863V13.1136C15.9091 12.7997 15.6548 12.5454 15.3409 12.5454Z"
        fill={svars.colorPrimaryHover}
      />
    </svg>
  );
}

export default MenuBarsIcon;
