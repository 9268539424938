import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import { capitalize } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  background: ${svars.colorWhite};
  padding: ${({ padded }) =>
    padded ? `${svars.spaceNormalLarge} ${svars.spaceMedium}` : 0};
  // Right padding would display between scroll bar and side of screen for scrollable fields
  padding-right: 0;
`;

const FieldHeader = styled.div`
  margin: auto 0;
  color: ${svars.fontColorLighter};
  white-space: nowrap;
  padding-right: ${svars.spaceNormalLarge};
`;

const FieldValueContainer = styled.div`
  flex-grow: 1;
  align-items: flex-start;
  display: contents;
  overflow: unset;
  width: 100%;
  & span {
    white-space: pre-wrap;
  }
`;

export function PaneFieldValuePlaceholder() {
  return (
    <Placeholder
      style={{
        width: '100%',
        marginRight: svars.spaceMedium,
      }}
    >
      <Placeholder.Line style={{ height: '5px' }} />
      <Placeholder.Line style={{ height: '5px' }} />
    </Placeholder>
  );
}

function FieldValue({
  header,
  loading,
  id,
  feedback,
  value,
  Cell,
  getCellProps,
  LoadingCell,
}) {
  const loadingState =
    loading &&
    ((LoadingCell && <LoadingCell />) || (LoadingCell === null && <div />) || (
      <PaneFieldValuePlaceholder />
    ));
  return (
    <>
      {((!loading || loadingState) && header) || ''}
      <FieldValueContainer>
        {loadingState ||
          (Cell && (
            <Cell
              padded
              feedbackId={feedback.id}
              formId={id}
              value={value}
              {...(getCellProps ? getCellProps(feedback) : {})}
            />
          )) ||
          value}
      </FieldValueContainer>
    </>
  );
}

FieldValue.propTypes = {
  header: PropTypes.node,
  loading: PropTypes.bool,
  id: PropTypes.string,
  feedback: PropTypes.shape({ id: PropTypes.string }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  Cell: PropTypes.func,
  getCellProps: PropTypes.func,
  LoadingCell: PropTypes.func,
};
FieldValue.defaultProps = {
  header: null,
  loading: false,
  id: null,
  feedback: {},
  value: null,
  Cell: null,
  getCellProps: null,
  LoadingCell: undefined,
};

const SectionHeader = styled.div`
  &&& {
    position: sticky;
    top: 0;
    z-index: 2;
    padding: ${svars.spaceMedium} ${svars.spaceMedium} ${svars.spaceMedium} 0;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    font-weight: ${svars.fontWeightMedium};
    font-size: ${svars.fontSizeLarge};
    background: ${svars.colorLightestGrey};
    border-bottom: ${svars.lightBorderStyle};
  }
`;

function PaneFields({
  header: sectionHeader,
  headerIcon,
  headerAction,
  feedback,
  fields,
  loading,
  alwaysShow,
}) {
  if (!fields?.length) return null;
  // Fields are hidden if they are empty and alwaysShow is false
  const fieldValuesAndIsHidden = fields.map(({ accessor }) => {
    const value =
      (!loading &&
        ((typeof accessor === 'function' && accessor(feedback)) ||
          feedback?.[accessor] ||
          '-')) ||
      null;
    return [
      value,
      !loading && // Don't show empty fields if not loading
        !alwaysShow && // Don't show empty fields if alwaysShow is false
        (!value ||
          (typeof value === 'object' && value.length === 0) ||
          value === '-'),
    ];
  });
  if (fieldValuesAndIsHidden.every(([, empty]) => empty)) return null;

  return (
    <>
      {sectionHeader ? (
        <SectionHeader>
          <span>
            {headerIcon ? (
              <Icon
                name={headerIcon}
                style={{
                  fontSize: 'inherit',
                  margin: `0 ${svars.spaceMedium}`,
                }}
              />
            ) : null}
            <Trans id={sectionHeader} render={capitalizedTranslation} />
          </span>
          {headerAction}
        </SectionHeader>
      ) : null}
      {fields.map(({ id, key, header, scrollable, ...valueProps }, i) => {
        const [value, isHidden] = fieldValuesAndIsHidden[i];
        if (isHidden) {
          return null;
        }
        return (
          <FieldContainer
            scrollable={scrollable ? '1' : null}
            padded={!scrollable ? '1' : null}
            key={`${header}-${id || key}`}
          >
            <FieldValue
              id={id}
              header={
                header ? (
                  <FieldHeader>{capitalize(header)} : </FieldHeader>
                ) : null
              }
              loading={loading}
              feedback={feedback}
              value={value}
              {...valueProps}
            />
          </FieldContainer>
        );
      })}
    </>
  );
}

PaneFields.propTypes = {
  header: PropTypes.string,
  headerIcon: PropTypes.string,
  headerAction: PropTypes.node,
  feedback: PropTypes.shape({ id: PropTypes.string }),
  fields: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  alwaysShow: PropTypes.bool,
};
PaneFields.defaultProps = {
  header: null,
  headerIcon: null,
  headerAction: null,
  feedback: {},
  fields: [],
  loading: false,
  alwaysShow: false,
};

export default PaneFields;
