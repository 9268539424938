import React from 'react';

import PropTypes from 'prop-types';

import { Legend } from 'recharts';

const handleMouseEnter =
  ({ legendState, onStateChange, useChartIndex }) =>
  ({ dataKey }, index) => {
    onStateChange({
      ...legendState,
      hovered: useChartIndex ? index : dataKey,
    });
  };

const handleMouseLeave =
  ({ legendState, onStateChange }) =>
  () =>
    onStateChange({ ...legendState, hovered: null });

const handleClick =
  ({ legendState, onStateChange, useChartIndex }) =>
  ({ dataKey }, index) => {
    const { clicked } = legendState;
    const indexedElement = useChartIndex ? index : dataKey;
    const keyIndex = clicked.findIndex((item) => item === indexedElement);
    let newClicked;
    if (keyIndex >= 0) {
      newClicked = clicked.filter((item) => item !== indexedElement);
    } else {
      newClicked = [...clicked, indexedElement];
    }
    onStateChange({
      ...legendState,
      clicked: newClicked,
    });
  };

function interactiveLegend({
  legendState,
  onStateChange,
  useChartIndex,
  ...legendProps
}) {
  return (
    <Legend
      onClick={handleClick({ legendState, onStateChange, useChartIndex })}
      onMouseEnter={handleMouseEnter({
        legendState,
        onStateChange,
        useChartIndex,
      })}
      onMouseLeave={handleMouseLeave({
        legendState,
        onStateChange,
        useChartIndex,
      })}
      wrapperStyle={{
        ...(legendProps.wrapperStyle || {}),
      }}
      formatter={(value, entry, index) => {
        const indexedElement = useChartIndex ? index : entry.dataKey;
        const textDecoration =
          (legendState.clicked.includes(indexedElement) && 'line-through') ||
          (legendState.hovered === indexedElement && 'underline') ||
          'none';
        return (
          <span style={{ cursor: 'pointer', textDecoration }}>{value}</span>
        );
      }}
      {...legendProps}
    />
  );
}

interactiveLegend.getInitialState = () => ({ hovered: null, clicked: [] });

interactiveLegend.getOpacity = (
  legendState,
  dataKeyOrChartIndex,
  minOpacity,
  maxOpacity
) => {
  const { clicked, hovered } = legendState;
  if (clicked.includes(dataKeyOrChartIndex)) return minOpacity;
  if (hovered === dataKeyOrChartIndex) return (maxOpacity + minOpacity) / 2;
  return maxOpacity;
};

interactiveLegend.propTypes = {
  legendState: PropTypes.shape({
    hovered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    clicked: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  }).isRequired,
  onStateChange: PropTypes.func.isRequired,
  useChartIndex: PropTypes.bool,
  // Recharts legend format props
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

interactiveLegend.defaultProps = {
  useChartIndex: false,
  layout: 'horizontal',
  align: 'center',
};

export default interactiveLegend;
