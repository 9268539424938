import React from 'react';

import PropTypes from 'prop-types';
import { Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import { formatDate } from 'reducers/locale';

import CopyToClipboard from 'components/ui/CopyToClipboard';
import { LimitedTextCell } from 'components/ui/Text';
import SentimentCell from 'components/ui/cells/SentimentCell';

import commonPropTypes from 'utils/commonPropTypes';
import { numberFormatter } from 'utils/formatter';
import { roundFloatedValue } from 'utils/helpers';

import * as svars from 'assets/style/variables';

export function PlaceholderCell() {
  return (
    <Placeholder
      style={{
        background: svars.colorGreyMedium,
        animationPlayState: 'paused',
        margin: svars.spaceNormal,
      }}
    >
      <Placeholder.Line />
    </Placeholder>
  );
}

export function ValueIndicatorCell({ value }) {
  return (
    <CellContainer
      style={{
        color:
          (value > 0 && svars.colorSuccess) ||
          (value < 0 && svars.colorDanger) ||
          svars.fontSizeBase,
      }}
    >
      {roundFloatedValue(value, true, '-')}
    </CellContainer>
  );
}

ValueIndicatorCell.propTypes = {
  value: PropTypes.number,
};
ValueIndicatorCell.defaultProps = { value: null };

const cellPropTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
};

export function ReactTableTextCell({ padded, value, style }) {
  return (
    <LimitedTextCell
      style={{
        margin: '0',
        paddingRight: padded ? svars.spaceNormalLarge : 0,
        width: '100%',
        display: 'block',
        ...style,
      }}
    >
      {value}
    </LimitedTextCell>
  );
}

ReactTableTextCell.propTypes = {
  ...cellPropTypes,
  style: commonPropTypes.style,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};
ReactTableTextCell.defaultProps = { style: {}, value: null };

ReactTableTextCell.propTypes = {
  ...cellPropTypes,
  style: commonPropTypes.style,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};
ReactTableTextCell.defaultProps = { style: {}, value: null };

export function EllipsedTextCell({ value }) {
  return (
    <LimitedTextCell
      style={{
        whiteSpace: 'nowrap',
        width: '100%',
        display: 'inline-block',
        paddingRight: svars.spaceNormalLarge,
      }}
    >
      {value}
    </LimitedTextCell>
  );
}
EllipsedTextCell.propTypes = cellPropTypes;
export function CopyableTextCell({ value }) {
  return (
    value && (
      <LimitedTextCell style={{ height: '100%', display: 'block' }}>
        <CopyToClipboard
          text={value}
          iconName="linkify"
          style={{ whiteSpace: 'nowrap' }}
        >
          {value}
        </CopyToClipboard>
      </LimitedTextCell>
    )
  );
}

CopyableTextCell.propTypes = cellPropTypes;

export function DateTimeCell({ value, inline }) {
  return (
    <LimitedTextCell
      style={{
        height: '100%',
        display: 'flex',
      }}
    >
      <span
        style={{ display: 'flex', flexDirection: inline ? 'row' : 'column' }}
      >
        {value ? (
          <>
            {formatDate(new Date(value), 'P')}

            <span
              style={{
                color: svars.fontColorLighter,
                marginLeft: inline ? svars.spaceNormal : 0,
              }}
            >
              {formatDate(new Date(value), 'p')}
            </span>
          </>
        ) : (
          '-'
        )}
      </span>
    </LimitedTextCell>
  );
}

DateTimeCell.propTypes = { ...cellPropTypes, inline: PropTypes.bool };
DateTimeCell.defaultProps = { inline: false };

export function DayCell({ value }) {
  return (
    <LimitedTextCell
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span style={{ display: 'inline-block', textAlign: 'start' }}>
        {value ? formatDate(new Date(value), 'P') : '-'}
      </span>
    </LimitedTextCell>
  );
}

DayCell.propTypes = { value: PropTypes.string };
DayCell.defaultProps = { value: null };

const TagLabel = styled.span`
  border-bottom: 3px solid transparent;
  border-radius: 3px;
  padding: ${svars.spaceXSmall} 4px;
  border-color: ${({ color }) => color};
  color: ${svars.fontColorBase};
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function LabelCell({ value, color, basic, getColor }) {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {(value && (
        <TagLabel
          basic={basic}
          color={color || (getColor && getColor(value)) || null}
        >
          {value}
        </TagLabel>
      )) ||
        '-'}
    </div>
  );
}

LabelCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  color: PropTypes.string,
  getColor: PropTypes.func,
  basic: PropTypes.bool,
};
LabelCell.defaultProps = {
  color: null,
  getColor: null,
  basic: null,
  value: null,
};
LabelCell.getLabelCell = (getColor) => (props) =>
  <LabelCell getColor={getColor} {...props} />;

export function ReactTableSentimentCell({ value }) {
  return <SentimentCell sentiment={value} />;
}
ReactTableSentimentCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export function SerializedCopyableTextCell({ value }) {
  return <CopyableTextCell value={JSON.stringify(value, null, 2)} />;
}

SerializedCopyableTextCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
};
SerializedCopyableTextCell.defaultProps = { value: null };

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: ${svars.borderRadius};
  white-space: nowrap;
`;

const VolumeCellIndicatorBar = styled.div`
  border-radius: ${svars.ctaBorderRadius};
  min-width: 70px;
  width: 100%;
  max-width: 100px;
  text-align: center;
  color: ${svars.fontColorBase};
  font-weight: ${svars.fontWeightLight};

  & div {
    border-radius: ${svars.ctaBorderRadius};

    width: ${({ valueRatio }) => 20 + 0.75 * valueRatio * 100}%;
    background-image: ${svars.VolumGradient};
    color: white;
    height: 0.3rem;

    transition: all 0.2s ease-out;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: inset 100px 0px 50px rgba(255, 255, 255, 0.25);
  }
`;

export function ReactTableVolumeCell({ maxNChunks, value }) {
  return (
    <CellContainer>
      <VolumeCellIndicatorBar valueRatio={value / maxNChunks}>
        {numberFormatter(value)}
        <div />
      </VolumeCellIndicatorBar>
    </CellContainer>
  );
}

export const reactTableVolumeCellFactory = (maxNChunks) => {
  function VolumeCell({ value }) {
    return <ReactTableVolumeCell maxNChunks={maxNChunks} value={value} />;
  }
  VolumeCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };
  return VolumeCell;
};

ReactTableVolumeCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxNChunks: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ReactTableVolumeCell.defaultProps = { value: null, maxNChunks: 0 };
