import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styled from 'styled-components';

import PageBreadcrumbs from 'components/ui/navigation/PageBreadcrumbs';
import logoImageSrc from 'components/ui/svg/logo.svg';

import * as svars from 'assets/style/variables';

import UserControls from './UserControls';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${svars.spaceMedium};
  font-size: ${svars.fontSizeXLarge};
  width: 100%;
  height: ${svars.breadcrumbsHeight};
  box-shadow: ${svars.primaryBoxShadow};
  height: ${svars.navbarHeight};

  background-color: ${svars.colorWhite};
  z-index: 4;
  top: 0;
`;
const LogoContainer = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    min-height: 45px;
    transition: transform 0.15s;
    border: none;
    margin: ${svars.spaceSmall} ${svars.spaceNormalLarge};

    &:hover {
      transform: scale(1.05);
    }
    :before {
      width: 0px;
    }
  }
`;

export const ImgLogo = styled.img`
  &&&&&&& {
    width: ${svars.logoWidth};
  }
`;

function V3Navbar() {
  return (
    <Container>
      <span style={{ display: 'inline-flex', flexGrow: 1 }}>
        <LogoContainer as={RouterLink} to="/" key="logo" name={logoImageSrc}>
          <ImgLogo src={logoImageSrc} alt="No logo" />
        </LogoContainer>
        <PageBreadcrumbs />
      </span>
      <UserControls style={{ margin: 0 }} />
    </Container>
  );
}

V3Navbar.propTypes = {};
V3Navbar.defaultProps = {};

export default V3Navbar;
