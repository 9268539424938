import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMatches, useNavigate, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import omit from 'lodash/omit';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  campaignConfigurationSelectorFactory,
  campaignsSelector,
} from 'selectors/campaign';
import { facetsSelector } from 'selectors/facet';
import { viewFacetSelector } from 'selectors/view';

import { LinkText, RouterLinkV2 } from 'components/ui/Link';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';

import { capitalize } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import AppsMenu from './AppsMenu';

const SeparatorContainer = styled.span`
  & i {
    margin: 0;
  }
`;

const StrongHoverRouterLink = styled(RouterLinkV2)`
  padding: ${svars.spaceNormal};
  border-radius: ${svars.ctaBorderRadius};
`;

function Separator() {
  return (
    <SeparatorContainer>
      <Icon name="chevron right" />
    </SeparatorContainer>
  );
}
const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: ${svars.fontSizeLarge};
  width: 100%;
  height: ${svars.breadcrumbsHeight};
  color: ${svars.colorPrimary};
`;

export function pageCrumbFactory(
  name,
  isLink = true,
  collection = [],
  selectedValue = null,
  headerLeftIconName = null,
  isItemDisabled = () => false
) {
  function contextualizedCrumb({ match, isLast, currentId }) {
    const navigate = useNavigate();

    const navigateTo = (event, { value }) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(window.location.pathname.replace(`${currentId}`, `${value}`));
    };

    const formatted = <Trans render={capitalizedTranslation} id={name} />;
    const key = `pcfpc-${name}`;
    const options = useMemo(
      () =>
        collection?.map((item) => ({
          ...omit(item, ['favorite']),
          key: item.id,
          value: item.id,
          text: item.name,
          disabled: isItemDisabled(item),
        })) || null,
      [collection]
    );
    return (
      (collection?.length > 1 && selectedValue && (
        <InTextDropdown
          trigger={
            <>
              {headerLeftIconName ? (
                <Icon
                  style={{
                    opacity: svars.iconOpacityLighter,
                    marginRight: svars.spaceNormal,
                    marginLeft: 0,
                  }}
                  name={headerLeftIconName}
                />
              ) : null}
              <span
                style={{
                  maxWidth: '300px',
                  display: 'inline-block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  verticalAlign: 'top',
                }}
              >
                {capitalize(name)}
              </span>
            </>
          }
          icon={
            <Icon style={{ opacity: svars.iconOpacityLighter }} name="sort" />
          }
          basic
          value={selectedValue}
          onChange={navigateTo}
          options={options}
        />
      )) ||
      ((isLast || !isLink) && (
        <LinkText
          key={key}
          base
          primary={isLast ? '1' : null}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: svars.spaceNormal,
          }}
        >
          {formatted}
        </LinkText>
      )) || (
        <StrongHoverRouterLink
          key={key}
          base="true"
          primary="true"
          to={match.pathname}
          style={{
            textDecoration: 'none',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: 0,
          }}
        >
          {formatted}
        </StrongHoverRouterLink>
      )
    );
  }
  return contextualizedCrumb;
}

function PageBreadcrumbs() {
  const matches = useMatches();
  if (matches && matches[matches.length - 1].handle?.crumb === null)
    return null;
  const crumbs = matches.filter((match) => Boolean(match.handle?.crumb));
  const FirstCrumb = crumbs?.[0]?.handle?.crumb;
  return crumbs.length ? (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {crumbs.length === 1 ? (
          <AppsMenu>
            <FirstCrumb
              key={0}
              match={crumbs[0]}
              isLast={crumbs.length === 1}
            />
          </AppsMenu>
        ) : (
          <>
            <AppsMenu />
            {crumbs
              .map((match, i) => (
                <match.handle.crumb
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  match={match}
                  isLast={i === crumbs.length - 1}
                />
              ))
              .reduce(
                (acc, item, iReduce) =>
                  acc.length > 0
                    ? // eslint-disable-next-line react/no-array-index-key
                      [...acc, <Separator key={`pcse-${iReduce}`} />, item]
                    : [item],
                []
              )}
          </>
        )}
      </div>
    </Container>
  ) : null;
}

export function CampaignCrumb({ match, isLast }) {
  const { campaignId } = useParams();
  const campaign = useSelector(
    campaignConfigurationSelectorFactory(campaignId)
  );
  const campaigns = useSelector(campaignsSelector);
  let crumbText = '...';
  if (campaign) crumbText = campaign.name;
  return pageCrumbFactory(
    crumbText,
    true,
    campaigns,
    campaign?.id
  )({ match, isLast, currentId: campaignId });
}

export function FacetCrumb({ match, isLast }) {
  const facet = useSelector(viewFacetSelector);
  const facets = useSelector(facetsSelector);
  let crumbText = '...';
  if (facet) crumbText = facet.name;
  return pageCrumbFactory(
    crumbText,
    true,
    facets,
    facet?.id,
    null,
    (item) => item?.status !== 'ready'
  )({
    match,
    isLast,
    currentId: facet?.id,
  });
}

export default PageBreadcrumbs;
