import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Dropdown, Flag, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { switchLanguage } from 'actions/locale';
import { toggleSupportModal } from 'actions/ui';
import { logoutUser } from 'actions/user';
import { languageSelector, LANGUAGES as languages } from 'reducers/locale';

import HoverableIcon from 'components/ui/icon/HoverableIcon';

import config from 'config';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const NestedDropdown = styled(Dropdown)`
  &&&&&& {
    padding: ${svars.spaceMedium} ${svars.spaceMediumLarge};
  }
`;

const NestedDropdownItem = styled(Dropdown.Item)`
  &&&&&& {
    /* Remove padding to add it to the dropdown header itself so click event happens
     on the whole dedicated space */
    padding: 0 !important;
  }
`;

NestedDropdown.propTypes = { ...Dropdown.propTypes, text: PropTypes.node };

function UserControls({ style, direction, children }) {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const onLogoutUser = useCallback(() => dispatch(logoutUser()), []);
  const onToggleSupportModal = useCallback(
    () => dispatch(toggleSupportModal()),
    []
  );
  const onSwitchLanguage = useCallback(
    (event, { value }) => dispatch(switchLanguage(value)),
    []
  );
  return (
    <span
      style={{
        margin: 'auto',
        ...style,
      }}
    >
      <Dropdown
        key="drpdn-user-menu"
        direction={direction}
        icon={null}
        trigger={
          children || (
            <div>
              <HoverableIcon
                style={{ margin: svars.spaceNormal }}
                name="ellipsis vertical"
                size={null}
              />
            </div>
          )
        }
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            content={t`help-support`}
            icon={<Icon color="blue" name="help" />}
            onClick={onToggleSupportModal}
          />
          {!config.FORCE_CUSTOM_LOCALE && (
            <NestedDropdownItem onClick={(e) => e.preventDefault()}>
              <NestedDropdown
                text={
                  <span
                    style={{
                      display: 'inline-flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginLeft: svars.spaceMediumLarge,
                      width: '100%',
                    }}
                  >
                    {capitalize(t`language`)}
                    <Flag
                      style={{
                        marginRight: 0,
                        marginLeft: svars.spaceMedium,
                      }}
                      name={language?.flag}
                    />
                  </span>
                }
                style={{
                  display: 'inline-flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
                options={languages}
                value={language?.value}
                onChange={onSwitchLanguage}
              />
            </NestedDropdownItem>
          )}

          <Dropdown.Item
            content={t`log-out`}
            icon={<Icon name="sign out" color="red" />}
            onClick={onLogoutUser}
          />
        </Dropdown.Menu>
      </Dropdown>
    </span>
  );
}

UserControls.propTypes = {
  style: PropTypes.shape(),
  direction: PropTypes.string,
  children: PropTypes.node,
};
UserControls.defaultProps = {
  style: {},
  direction: 'left',
  children: null,
};

export default UserControls;
