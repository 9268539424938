import React from 'react';

import PropTypes from 'prop-types';

import { renderSvgTextSpans } from 'utils/svg';

import * as styleVariables from 'assets/style/variables';

function CustomAxisTick({
  x,
  y,
  labelMaxRows,
  payload,
  labelFormatter,
  axis,
  width,
  angle,
}) {
  return (
    <g
      transform={
        axis === 'y'
          ? `translate(${x - 3}, ${y - 3})`
          : `translate(${x - 3}, ${y}) rotate(${angle})`
      }
    >
      <text
        textAnchor={axis === 'x' ? 'start' : 'end'}
        dominantBaseline={axis === 'x' ? 'middle' : 'hanging'}
        alignmentBaseline="middle"
      >
        {renderSvgTextSpans(
          labelFormatter(payload.value),
          0,
          width,
          0,
          labelMaxRows * 14,
          null,
          false,
          {},
          false,
          styleVariables.svgCharLengthFontSizeSmall,
          10
        )}
      </text>
    </g>
  );
}

CustomAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  labelFormatter: PropTypes.func,
  axis: PropTypes.string.isRequired,
  angle: PropTypes.number,
  // Max number of texts rows - if text is not complete, add ellipsis
  labelMaxRows: PropTypes.number,
};
CustomAxisTick.defaultProps = {
  labelFormatter: (item) => item,
  payload: { value: '' },
  x: undefined,
  y: undefined,
  width: undefined,
  angle: 35,
  labelMaxRows: 4,
};

export default CustomAxisTick;
